<template>
  <div>
    <div
      v-if="node.id"
      :class="['issue_line', { selected: isSelected }]"
      @click="select"
      ref="node"
    >
      <div
        :style="{ 'margin-left': (node.path.length - 1) * 20 + 'px' }"
        class="issue_label"
        ref="label"
      >
        {{ node.label }}
      </div>
    </div>
    <issue-node
      v-for="child in node.children"
      :key="child.id"
      :node="child"
      :selected="selected"
      @select="forwardSelect"
    ></issue-node>
  </div>
</template>

<script>
import IssueNode from "./IssueNode.vue";


function needsScroll(el) {
    var node = el.getBoundingClientRect();
    var container = el.closest('.issues_tree').getBoundingClientRect();
    if (node.top < container.top) return -container.top;
    if (node.bottom > container.bottom) return container.bottom;
    return false;
}


export default {
  name: "IssueNode",
  props: ["node", "selected"],
  components: {
    IssueNode,
  },
  computed: {
    isSelected() {
      if (!this.node.id) return false;
      return this.node === this.selected;
    },
  },
  methods: {
    select() {
      this.forwardSelect(this.node);
    },
    forwardSelect(node) {
      this.$emit("select", node);
    },
    showNodeIfNeeded() {
      if (!this.isSelected) return;
      const el = this.$refs.label;
      const scrollNeeded = needsScroll(el);
      if (!scrollNeeded) return;
      this.$refs.label.scrollIntoView(scrollNeeded < 0);
    }
  },
  watch: {
    isSelected() {
      this.showNodeIfNeeded();
    },
  },
  mounted() {
    this.showNodeIfNeeded();
  },
};
</script>
