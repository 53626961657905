<style lang="scss">
@import "./panel-row";
</style>

<template>
  <div class="panel">
    <div class="panel_title">Time</div>
    <issue-estimated-passed :issue="issue" :barMaxHeight="barMaxHeight" />
    <hr />
    <div class="panel_title">Progression</div>
    <div class="panel_row">
      <div class="panel_col">
        <span class="panel_sub">{{ doneRatioBefore }}%</span>
        <span
          class="panel_bar"
          :style="doneRatioBeforeStyle"
        ></span>
        <span class="panel_sub">previous</span>
      </div>
      <div class="panel_col">
        <span class="panel_sub"><b>{{ doneRatio }}%</b></span>
        <span
          class="panel_bar"
          :style="doneRatioStyle"
        ></span>
        <span class="panel_sub">current</span>
      </div>
    </div>
    <hr />
    <div class="panel_title">Indicators</div>
    <div class="panel_row">
      <div class="panel_col">
        <div class="panel_figure">{{ raf }}</div>
        <span class="panel_sub"><abbr title="Reste à Faire">RAF</abbr></span>
      </div>
      <div class="panel_col">
        <div class="panel_figure">{{ dpp }}</div>
        <span class="panel_sub"
          ><abbr title="Dépassement Projet Potentiel">DPP</abbr></span
        >
      </div>
      <div class="panel_col">
        <div class="panel_figure">{{ dad }}</div>
        <span class="panel_sub"
          ><abbr title="Dépassement à Date">DAD</abbr></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { formatK, getDAD, getDPP } from "../tree/tree-helpers";
import { ratioToIdealToHsl } from "./issue-helper";
import IssueEstimatedPassed from "./IssueEstimatedPassed.vue";

export default {
  props: ["timeEntry"],
  data() {
    return {
      previousHours: null,
      barMaxHeight: 40,
    };
  },
  components: {
    IssueEstimatedPassed,
  },
  computed: {
    issue() {
      return this.timeEntry.issue;
    },
    doneRatio() {
      return this.issue.agg_done_ratio;
    },
    doneRatioBefore() {
      return this.timeEntry.done_ratio_before;
    },
    doneRatioAfter() {
      return this.timeEntry.done_ratio_after;
    },
    estimated() {
      return parseFloat(this.issue.agg_estimated_hours) || 0;
    },
    passed() {
      return this.issue.agg_passed_hours;
    },
    doneRatioIdeal() {
      if (!this.estimated && !this.passed) return 0;
      if (!this.estimated) return 100;
      const ideal = (this.passed / this.estimated) * 100;
      return Math.round(ideal);
      // return ideal < 100 ? Math.round(ideal) : 100;
    },
    doneRatioStyle() {
      let ratio = this.doneRatio;
      let ideal = this.doneRatioIdeal;
      return {
        'height': this.barMaxHeight * ratio / 100 + 'px',
        'background-color': ratioToIdealToHsl(ratio, ideal),
        'border-color': ratioToIdealToHsl(ratio, ideal, true),
      };
    },
    doneRatioBeforeStyle() {
      let before = this.doneRatioBefore;
      let ideal = this.doneRatioIdeal;
      return {
        'height': this.barMaxHeight * before / 100 + 'px',
        'background-color': ratioToIdealToHsl(before, ideal),
        'border-color': ratioToIdealToHsl(before, ideal, true),
      };
    },

    raf() {
      return formatK(this.timeEntry.raf) + 'h';
    },
    dpp() {
      const dpp = formatK(getDPP(this.issue, true));
      return dpp === Infinity ? '∞' : dpp + 'h';
    },
    dad() {
      const dad = formatK(getDAD(this.issue, true));
      return dad === Infinity ? '∞' : dad + 'h';
    },
  },
};
</script>
