import dayjs from 'dayjs';
import FlatPickr from 'vue-flatpickr-component';
import * as Helpers from "@/views/dashboard/dashboard-helpers";
import { DATE_FORMAT } from '@/config/constants';


const weekDays = [
    { id: 1, label: 'Mon' },
    { id: 2, label: 'Tue' },
    { id: 3, label: 'Wed' },
    { id: 4, label: 'Thu' },
    { id: 5, label: 'Fri' },
];


export default {
    name: 'PlanPicker',
    props: {
        resources: {
            type: Array,
        },
        lockedUntil: {
            type: String,
        },
        defaultStart: {
            type: String,
        },
    },
    components: { FlatPickr },
    data() {
        return {
            dateConfig: {
                mode: 'range',
                minDate: this.lockedUntil,
                locale: { firstDayOfWeek: 1, },
                defaultDate: this.defaultStart,
            },
            days: weekDays.map(d => d.id),
            weekDays,
            startDate: null,
            endDate: null,
            holidays: [],
        };
    },
    computed: {
        dates() {
            if (!this.startDate || !this.endDate) return [];
            return Helpers.getBusinessDates(this.startDate, this.endDate, this.holidays, this.days);
        }
    },
    methods: {
        updateRange(selectedRange) {
            this.startDate = null;
            this.endDate = null;
            if (selectedRange.length !== 2) return;
            this.startDate = selectedRange[0];
            this.endDate = selectedRange[1];
            const from = dayjs(this.startDate).format(DATE_FORMAT);
            const to = dayjs(this.endDate).format(DATE_FORMAT);
            const country = this.resources[0].country;
            this.$store.dispatch('TimeEntry/list/getPublicHolidays', { from, to }).then(data => {
                this.holidays = data.public_holidays.filter(entry => entry.country === country).map(entry => entry.date);
                this.emitDates();
            });
        },
        emitDates() {
            this.$emit('select', this.dates);
        },
    },
};
