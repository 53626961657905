import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import FlatPickr from 'vue-flatpickr-component';
import Multiselect from 'vue-multiselect';
import TimePicker from './TimePicker.vue';
import IconOpener from "@/icons/calendar-plus-solid.svg";
import IconRabbit from "@/icons/rabbit.svg";
import IconCopy from "@/icons/copy-solid.svg";
import IconTree from "@/icons/sitemap-solid.svg";
import IconDevops from "@/icons/azure-devops.svg";
import FormError from '@/views/common/FormError.vue';
import Modal from "@/views/modal/Modal.vue";
import IssuePanel from "./IssuePanel.vue";
import IssuePicker from "./issue-picker/IssuePicker.vue";
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import PlanPicker from "./PlanPicker.vue";
import {
    capAvailableHoursForIssue,
    getResourceAvailableHours,
    getResourceDateTimeEntries,
    normalizedContains,
    getIssueLink,
    filterResourceByName,
    userIsAtLeastExpert,
    getLockedUntil,
    projectHasMembers,
} from '@/app/helpers';
import {
    getRAF,
    getDoneRatio,
    toTree,
} from "@/views/tree/tree-helpers";
import * as Redmine from '@/config/redmine-constants';
import { TimeEntry, TimeEntryInput, TimeModalSelection as Selection } from '@/app/types/interfaces';
import * as Config from "@/config/constants";
import { timeEntryToDayjs } from '@/app/calendar-helpers';


function addPathToIssues(issues) {
    let issueTree = toTree(issues);
    issues.forEach(issue => {
        issue.path = issueTree[issue.id].path
            .filter(issueId => issueId != issue.id)
            .map(issueId => issueTree[issueId].data.subject).join(' > ');
    });
}


const ERROR_LABELS = {
    doneRatio: 'Progression à 0% et validation de temps réalisé sont incompatibles',
    estimated: 'Impossible de saisir du temps sans estimé (Forfait)',
    node: 'Impossible de saisir du temps sur un nœud (avec sous-tâches)',
};


const excludedIssueStatuses = [
    Redmine.ISSUE_STATUS_REMOVED,
    Redmine.ISSUE_STATUS_CLOSED,
];


const defaultData = () => {
    return {
        dateConfig: {
            disable: [
                {
                    from: "2000-01-01",
                    to: getLockedUntil(null),
                }
            ],
            locale: { firstDayOfWeek: 1, }
        },
        timeConfig: {
            enableTime: true,
            noCalendar: true,
            time_24hr: true,
        },
        errors: {
            date: false,
            version: false,
            issue: false,
            hours: false,
            doneRatio: false,
            estimated: false,
            node: false,
            dates: false,
        },
        profiles: [],
        raf: null,
        wasFutureTime: true,
        resources: [],
        resourcesFiltered: [],
        project: null,
        date: null,
        versionId: null,
        issue: null,
        startTime: null,
        timeEntries: [],
        versionsAll: [],
        issues: [],
        issuesFiltered: [],
        hours: 0,
        doneRatio: 0,
        initialDoneRatio: 0,
        doneRatioBefore: 0,
        doneRatioAfter: 0,
        activityId: Redmine.DEFAULT_ACTIVITY,
        profileId: null,
        comment: '',
        futureTime: '1',
        log: null,
        readonly: false,
        multiple: false,
        dates: [],
        onlyIssue: false,
    };
};


export default {
    name: 'TimeModal',
    components: {
        FlatPickr,
        TimePicker,
        IconOpener,
        IconRabbit,
        IconCopy,
        IconTree,
        IconDevops,
        FormError,
        Modal,
        IssuePanel,
        Multiselect,
        IssuePicker,
        ResourceSelect,
        PlanPicker,
    },
    props: [
        'selection',
    ],
    data: defaultData,
    computed: {
        // @ts-ignore
        ...mapGetters({
            resourcesAll: 'Resource/list/resources',
            activities: 'Enumeration/list/activities',
            projectsAll: 'Project/list/projects',
            loading: 'App/loading/loading',
            user: 'Resource/auth/user',
        }),
        version() {
            return this.versionsAll.find(version => version.id == this.versionId);
        },
        versions() {
            return this.versionsAll.filter(version => version.status === 'open');
        },
        hasTimeEntries() {
            return this.timeEntries.length !== 0;
        },
        redmineIssueLink() {
            if (!this.issue) return '';
            return getIssueLink(this.issue.id);
        },
        formattedLog() {
            if (!this.log) return null;
            const who = (this.resourcesAll.find(res => res.id === parseInt(this.log.author_id)) || {}).fullname;
            const when = dayjs(this.log.updated_on).format(Config.DATE_FORMAT + ' à HH:mm');
            return `Modifié par ${who} le ${when}`;
        },
        projects() {
            const resources = this.resources;
            if (!resources.length) return [];
            return this.projectsAll.filter(project => {
                if (project.id === Redmine.ADMINISTRATIVE_PROJECT) return false;
                if (projectHasMembers(project, resources)) return true;
                if (project === this.project && resources.length === 1 && this.user === resources[0]) return true;
                return false;
            });
        },
        passedTime() {
            return !this.futureTime || this.futureTime === '0';
        },
        versionIsC2S() {
            if (!this.issue) return false;
            return this.issue.version_type === Redmine.CUSTOM_FIELD_VERSION_TYPE_C2S;
        },
        versionIsFlatRate() {
            if (!this.issue) return false;
            return this.issue.version_type === Redmine.CUSTOM_FIELD_VERSION_TYPE_FLATRATE;
        },
        issueIsNode() {
            if (!this.issue) return false;
            return this.issue.children_nb !== 0;
        },
        canSkipValidations() {
            if (userIsAtLeastExpert(this.user)) return true;
            return false;
        },
        futureIssue() {
            if (!this.issue) return null;
            const issueId = this.issue.id;
            const editing = this.timeEntries.find(entry => entry.issue_id === issueId);
            const passedIncr = this.passedTime ? this.hours : 0;
            const plannedIncr = !this.passedTime ? this.hours * (this.dates.length || 1) : 0;
            const passedDecr = editing && editing.future_time === Redmine.CUSTOM_FIELD_FUTURE_TIME_NO ? editing.hours : 0;
            const plannedDecr = editing && editing.future_time === Redmine.CUSTOM_FIELD_FUTURE_TIME_YES ? editing.hours : 0;
            const issue = {
                agg_estimated_hours: this.issue.agg_estimated_hours,
                agg_passed_hours: this.issue.agg_passed_hours + passedIncr - passedDecr,
                agg_planned_hours: this.issue.agg_planned_hours + plannedIncr - plannedDecr,
                agg_sold_hours: this.issue.agg_sold_hours,
                agg_pecc_hours: this.issue.agg_pecc_hours,
                agg_pecm_hours: this.issue.agg_pecm_hours,
                agg_done_ratio: this.doneRatio,
            };
            return issue;
        },
        timeEntry() {
            if (!this.issue) return null;
            return {
                creation: !this.hasTimeEntries,
                issue: this.futureIssue,
                hours: this.hours,
                done_ratio_before: this.doneRatioBefore,
                done_ratio_after: this.doneRatioAfter,
                raf: this.raf,
            };
        },
        errorList() {
            return Object.keys(ERROR_LABELS)
                .filter(key => this.errors[key])
                .map(key => ERROR_LABELS[key]);
        },
        showDoneRatio() {
            return this.issue
                && !this.issueIsNode
                && this.passedTime
                && !this.isAdministrativeProject
                && !this.multiple
                ;
        },
        doneRatioHasChanged() {
            return this.doneRatio != this.initialDoneRatio;
        },
        internalIssue() {
            if (!this.issue) return false;
            return Redmine.INTERNAL_VERSION_TYPES.includes(this.issue.version_type);
        },
        showIssuePanel() {
            return this.timeEntry && this.issue.version_type && !this.internalIssue;
        },
        rabbitSpeed() {
            if (!this.issue) return 0;
            const estimated = parseFloat(this.issue.agg_estimated_hours);
            const hours = this.hours;
            const ratioOffset = this.doneRatio - this.initialDoneRatio;
            const timeOffset = hours / estimated * 100;
            if (!this.passedTime || !estimated) return 0;
            if (ratioOffset <= 0) return 0;
            const speed = Math.floor(ratioOffset / timeOffset);
            return speed <= 4 ? speed : 4;
        },
        isAdministrativeProject() {
            return this.project && this.project.id === Redmine.ADMINISTRATIVE_PROJECT;
        },
        lockedUntil() {
            if (!this.project) return null;
            return getLockedUntil(this.project.locked_until);
        },
    },
    methods: {
        projectHasMembers,
        refreshIssues(issueId) {
            issueId = parseInt(issueId);
            if (!this.project) return Promise.resolve();
            const payload = (this.readonly || this.onlyIssue) && issueId ? { issueIds: [issueId] } : {
                projectId: this.project.id,
                versionIds: this.versionId ? [this.versionId] : [],
                statusIds: Redmine.ISSUE_STATUSES.filter(status => !excludedIssueStatuses.includes(status)),
            };
            return this.$store.dispatch('Issue/list/getList', payload).then((issues) => {
                if (issueId) {
                    this.issue = issues.find(issue => issue.id == issueId);
                    this.selectIssue();
                } else if (this.issue && !issues.find(issue => issue.id === this.issue.id)) this.issue = null;
                addPathToIssues(issues);
                this.issues = issues.filter(iss => !this.versionId || (iss.version_id === this.versionId));
                this.issuesFiltered = this.issues;
            });
        },

        setDefault(key) {
            this[key] = defaultData()[key];
        },

        selectResources() {
            if (!this.resources.length) return;
            if (this.projects.find(project => project === this.project)) return;
            this.project = null;
            this.issue = null;
            this.issues = [];
            this.versionsAll = [];
            this.versionId = null;
            this.profiles = [];
            this.preselectAvailableHours();
        },

        selectProject(issueId) {
            this.issue = null;
            this.issues = [];
            this.versionsAll = [];
            this.versionId = null;
            this.profiles = [];
            this.multiple = false;
            this.updateDatePicker();
            if (!this.project) return Promise.resolve();
            this.$store.dispatch('Issue/list/getVersions', this.project.id).then((versions) => {
                this.versionsAll = versions;
            });
            return this.refreshIssues(issueId);
        },

        selectVersion(dontRefreshIssues) {
            if (dontRefreshIssues !== true) {
                this.refreshIssues();
            }
        },

        selectIssue() {
            this.preselectAvailableHours();
            if (!this.issue) return;
            this.initialDoneRatio = this.issue.done_ratio;
            this.doneRatio = this.issue.done_ratio;
            this.doneRatioBefore = this.hasTimeEntries && this.timeEntries[0].done_ratio_before ? this.timeEntries[0].done_ratio_before : this.issue.done_ratio;
            this.doneRatioAfter = this.hasTimeEntries && this.timeEntries[0].done_ratio_after ? this.timeEntries[0].done_ratio_after : this.issue.done_ratio;
            this.updateRaf();
            if (this.issue.version_id) {
                this.versionId = this.issue.version_id;
            }
        },

        pickIssue(issue) {
            this.issue = issue;
            this.selectIssue();
        },

        preselectAvailableHours() {
            return;// TODO needs resource/day timeEntries (too expensive for now);
            if (this.resources.length !== 1) return;
            if (this.hasTimeEntries) return;
            const dateTimeEntries = getResourceDateTimeEntries(
                this.timeEntries,
                this.resources[0].id,
                this.date
            );
            const availableHours = getResourceAvailableHours(dateTimeEntries);
            this.hours = capAvailableHoursForIssue(this.issue, availableHours);
        },

        updateRaf() {
            if (!this.futureIssue) return;
            this.raf = Math.round(getRAF(this.futureIssue, true) * 4) / 4;
        },

        updateDoneRatio() {
            this.doneRatio = Math.round(getDoneRatio(this.futureIssue, this.raf) / 5) * 5;
        },

        searchResources(search) {
            this.resourcesFiltered = search
                ? this.resourcesAll.filter(resource => filterResourceByName(resource, search))
                : this.resourcesAll;
        },

        searchIssues(search) {
            if (!search) {
                this.issuesFiltered = this.issues;
                return;
            }
            this.issuesFiltered = this.issues.filter(issue =>
                normalizedContains(search, issue.subject)
                || search === issue.devops_id
                || search == issue.id
            );
        },

        updateDatePicker() {
            if (this.readonly) {
                this.dateConfig.disable = [];
            } else {
                this.dateConfig.disable = [{
                    from: "1970-01-01",
                    to: this.lockedUntil
                }];
                if (this.date && this.date <= this.lockedUntil) {
                    this.date = null;
                }
            }
            this.$refs.timeEntryDate.fp.set('disable', this.dateConfig.disable);
        },

        removeModalOpenClass() {
            document.body.classList.remove('modalopen');
        },

        validateRequired() {
            const requiredFields = ['date', 'issue', 'hours'];
            requiredFields.forEach(requiredField => {
                this.errors[requiredField] = !this[requiredField];
            });
        },

        validateDoneRatio() {
            if (this.canSkipValidations) return;
            this.errors.doneRatio =
                this.versionIsFlatRate &&
                this.passedTime &&
                this.doneRatio == 0
                ;
        },

        validateEstimated() {
            if (this.canSkipValidations) return;
            const estimated = parseFloat(this.issue.agg_estimated_hours);
            this.errors.estimated =
                this.versionIsFlatRate &&
                this.passedTime &&
                !estimated
                ;
        },

        validateNode() {
            if (this.canSkipValidations) return;
            this.errors.node = this.passedTime && this.issueIsNode;
        },

        validate() {
            this.validateRequired();
            this.validateDoneRatio();
            // this.validateEstimated();
            this.validateNode();
            return !Object.values(this.errors).find(error => error);
        },

        continueWithoutChangingDoneRatio() {
            return !(!this.internalIssue
                && this.wasFutureTime
                && this.passedTime
                && !this.doneRatioHasChanged)
                || window.confirm('Please confirm your validation without changing the progression %');
        },

        submit() {
            if (this.readonly) return;
            if (this.loading > 0) return;
            if (!this.validate()) return;
            if (!this.continueWithoutChangingDoneRatio()) return;
            if (this.isAdministrativeProject) this.futureTime = '0';
            let promises = [];
            const timeEntryInput: TimeEntryInput = {
                project: this.project,
                date: this.date,
                issue: this.issue,
                activityId: this.activityId,
                profileId: this.profileId,
                comment: this.comment,
                hours: this.hours,
                startTime: this.startTime,
                futureTime: this.futureTime,
                doneRatio: this.doneRatio,
                doneRatioBefore: this.doneRatioBefore,
                timeEntries: this.timeEntries,
            }
            if (this.resources.length > 1) {
                timeEntryInput.multiple = this.resources.map(res => ({ user_id: res.id }));
            } else {
                timeEntryInput.user = this.resources[0];
            }
            if (this.multiple && this.dates.length) {
                if (!window.confirm('Are you sure you want to create these ' + this.dates.length + ' time entries')) return;
                timeEntryInput.user = this.resources[0];
                delete timeEntryInput.date;
                timeEntryInput.multiple = this.dates.map(date => ({ spent_on: date }));
            }
            promises.push(this.$store.dispatch('Crud/upsertTimeEntry', timeEntryInput));
            if (this.doneRatioHasChanged) {
                promises.push(this.$store.dispatch('Crud/upsertIssue', {
                    id: this.issue.id,
                    done_ratio: this.doneRatio,
                }));
            }
            Promise.all(promises).then(() => {
                const action = this.hasTimeEntries ? 'Update' : 'Create';
                this.$gtag.event(action + ' TimeEntry', { event_category: 'Time Modal', });
                this.$emit('saved');
                this.close();
            });
        },

        copy() {
            if (!this.timeEntries.length || !this.issue) return;
            this.readonly = false;
            this.updateDatePicker();
            this.timeEntries = defaultData().timeEntries;
            this.doneRatioBefore = this.issue.done_ratio;
            this.doneRatioAfter = this.issue.done_ratio;
            this.$nextTick(() => {
                this.$refs.timeEntryDate.fp.open();
            });
        },

        close() {
            this.$emit('close');
        },

        remove() {
            let timeEntryInput: Partial<TimeEntryInput> = {
                timeEntries: this.timeEntries,
            };
            if (!window.confirm('Are you sure you want to delete this time entry ?')) return;
            this.$store.dispatch('Crud/removeTimeEntry', timeEntryInput).then(() => {
                this.$gtag.event('Delete TimeEntry', { event_category: 'Time Modal', });
                this.$emit('saved');
                this.close();
            });
        },

        removeMultiple() {
            let timeEntryInput: Partial<TimeEntryInput> = {
                user: this.resources[0],
                issue: this.issue,
                multiple: this.dates.map(date => ({ spent_on: date })),
            };
            if (!window.confirm('Are you sure you want to delete all the time entries matching these dates/resource/task ?')) return;
            this.$store.dispatch('Crud/removeTimeEntry', timeEntryInput).then(() => {
                this.$gtag.event('Delete TimeEntry', { event_category: 'Time Modal', });
                this.$emit('saved');
                this.close();
            });
        },

        getLogForTimeEntry(timeEntry) {
            this.$store.dispatch('TimeEntry/create/getLog', timeEntry.id).then(log => {
                this.log = log;
            })
        },

        initDataForSelection(selection: Selection) {
            if (selection.onlyIssue) {
                this.onlyIssue = true;
            }
            if (selection.resourceId) {
                this.resources = [this.resourcesAll.find(resource => resource.id == selection.resourceId)];
                this.selectResources();
            }
            if (selection.projectId) {
                this.project = this.projectsAll.find(project => project.id == selection.projectId);
                this.selectProject(selection.issueId);
            }
            if (selection.startTime) {
                this.startTime = selection.startTime;
            }
            this.hours = selection.hours || 1;
            this.date = selection.date || dayjs().format(Config.DATE_FORMAT);
            if (dayjs(this.date + (this.startTime ? (' ' + this.startTime) : '')) <= dayjs()) {
                this.futureTime = '0';
            }
        },

        initDataForTimeEntry(timeEntries: TimeEntry[]) {
            const timeEntry = timeEntries[0];
            this.readonly = timeEntry?.locked === 1 || false;
            this.onlyIssue = timeEntry?.locked === 2 || false;
            let selection: Selection = this.selection;
            if (timeEntry) {
                const datejs = dayjs(timeEntryToDayjs(timeEntry).toISOString());
                selection = {
                    resourceId: timeEntry.user_id,
                    projectId: timeEntry.project_id,
                    issueId: timeEntry.issue_id,
                    date: datejs.format(Config.DATE_FORMAT),
                    hours: timeEntry.hours,
                    startTime: timeEntry.start_time ? datejs.format(Config.TIME_FORMAT) : null,
                };
            }
            this.initDataForSelection(selection);
            if (!timeEntry) return;
            if (!this.selection.copy) {
                this.timeEntries = timeEntries;
                this.getLogForTimeEntry(timeEntry);
            }
            this.$emit('timeEntryLoaded', timeEntry);
            this.activityId = timeEntry.activity_id;
            this.profileId = timeEntry.profile_id;
            this.comment = timeEntry.comments;
            this.futureTime = timeEntry.future_time;
            this.wasFutureTime = !this.passedTime;
            const issue = { id: timeEntry.issue_id, subject: timeEntry.issue_name };
            this.issues = [issue];
            this.issue = issue;
        },

        initData() {
            this.resourcesFiltered = this.resourcesAll;
            let payload;
            if (this.selection.timeEntryId) {
                payload = { timeEntryId: this.selection.timeEntryId };
            }
            if (
                this.selection.resourceId
                && this.selection.date
                && this.selection.issueId
                && !this.selection.add
            ) {
                // Global planning selection
                payload = {
                    resourceIds: this.selection.resourceId,
                    from: this.selection.date,
                    to: this.selection.date,
                    issueId: this.selection.issueId,
                };
            }
            if (payload) {
                this.$store.dispatch('TimeEntry/list/getListArray', payload).then(({ timeEntries }) => {
                    this.initDataForTimeEntry(timeEntries);
                });
                return;
            }
            this.initDataForSelection(this.selection);
        },
    },
    watch: {
        hours() {
            this.updateRaf();
        },
        futureTime() {
            this.updateRaf();
            this.multiple = false;
        },
        issue(val) {
            if (val) return;
            this.multiple = false;
        },
        multiple() {
            this.dates = [];
        },
        versionId() {
            if (!this.versionId) {
                this.profiles = [];
                this.profileId = null;
                return;
            }
            this.$store.dispatch('Credit/list/getProfilesForVersion', this.versionId).then(({ profiles, lastProfileId }) => {
                this.profiles = profiles;
                if (!profiles.length) return;
                if (this.profileId) return;
                if (lastProfileId) return this.profileId = lastProfileId;
                this.profileId = profiles[0].id;
            });
        },
        'selection.timeEntryId': function () {
            this.initData();
        }
    },
    mounted() {
        const promises = [
            this.$store.dispatch('Project/list/getList'),
            this.$store.dispatch('Enumeration/list/getActivities'),
        ];
        Promise.all(promises).then(() => {
            this.initData();
        });
        this.$refs.hours.focus();
    }
};
