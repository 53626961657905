<style lang="scss">
@import "./issue-picker";
</style>

<template>
  <div class="issue_picker">
    <input
      type="text"
      ref="input"
      :class="['issue_search', 'form-control', { 'is-invalid': error }]"
      :value="inputValue"
      @focus="focus"
      @blur="blur"
      @input="filter"
      @keydown.up="shiftSelection('prev')"
      @keydown.down="shiftSelection('next')"
      @keydown.enter.stop="enter"
      @keydown.escape="escape"
      :disabled="disabled"
    />
    <div class="issues_tree" v-if="issues.length && issueFocused" tabindex="-1" ref="issueTree">
      <issue-node
        :node="rootNode"
        :selected="internalSelectedNode"
        @select="select"
        @close="blur"
      ></issue-node>
    </div>
  </div>
</template>

<script>
import {
  toTree,
  addMissingParentsToList,
  addPrevNextLinksToTree,
} from "@/views/tree/tree-helpers";
import { normalizedContains } from "@/app/helpers";
import IssueNode from "./IssueNode";
import { getFirstNode } from '../../tree/tree-helpers';

export default {
  name: "IssuePicker",
  props: {
    issues: {},
    selectedIssue: {},
    error: {},
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      issueFocused: false,
      issuesFiltered: this.issues,
      search: "",
      internalSelectedNode: null,
    };
  },
  components: {
    IssueNode,
  },
  computed: {
    nodes() {
      const nodes = toTree(this.issuesFiltered)
      addPrevNextLinksToTree(nodes);
      return nodes;
    },
    rootNode() {
      return this.nodes[0];
    },
    selectedNode() {
      return this.getNodeForIssue(this.selectedIssue);
    },
    inputValue() {
      if (!this.issueFocused) {
        return this.selectedIssue ? this.selectedIssue.subject : "";
      }
      return this.search;
    },
  },
  methods: {
    focus() {
      this.internalSelectedNode = this.selectedNode;
      this.issueFocused = true;
    },
    blur(e) {
      if (e.relatedTarget === this.$refs.issueTree) return;
      this.issueFocused = false;
    },
    filter(e) {
      const search = e.target.value;
      this.search = search;
      if (!search) {
        this.issuesFiltered = this.issues;
        return;
      }
      const issuesFiltered = this.issues.filter(
        (issue) =>
          normalizedContains(search, issue.subject) ||
          search === issue.devops_id ||
          search == issue.id
      );
      addMissingParentsToList(issuesFiltered, this.issues);
      this.issuesFiltered = issuesFiltered;
    },
    select(node) {
      this.$emit("select", node.data);
      this.issueFocused = false;
    },
    shiftSelection(direction) {
      if (this.internalSelectedNode) {
        this.internalSelectedNode = this.internalSelectedNode[direction];
        return;
      }
      const firstNode = getFirstNode(this.nodes);
      this.internalSelectedNode = direction === 'next' ? firstNode : firstNode.prev();
    },
    enter() {
      this.select(this.internalSelectedNode);
    },
    escape() {
      this.$refs.input.blur();
    },
    getNodeForIssue(issue) {
      return Object.values(this.nodes).find((node) => node.data === issue);
    },
  },
  watch: {
    issues() {
      this.search = "";
      this.issuesFiltered = this.issues;
    },
    selectedIssue() {
      this.internalSelectedNode = this.selectedNode;
    },
  },
};
</script>
