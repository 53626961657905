export function percentageToHsl(percentage, darker = false) {
    percentage = percentage > 1 ? 1 : percentage;
    const RED = -80;
    const GREEN = 120;
    let hue = percentage * (GREEN - RED) + RED;
    let lig = darker ? 50 : 60;
    const SAT = 50;
    return `hsl(${hue}, ${SAT}%, ${lig}%)`;
}

export function ratioToIdealToHsl(ratio, ideal, darker = false) {
    let percentage = ideal ? ratio / ideal : 1;
    return percentageToHsl(percentage, darker)
}
